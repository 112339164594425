<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="JOINT_INSPECTION_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="jointInspectionTypeCd"
            label="합동점검종류"
            v-model="searchParam.jointInspectionTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="JOINT_ITEM_KIND_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="jointItemKindCd"
            label="점검/평가 구분"
            v-model="searchParam.jointItemKindCd"
          ></c-select>
        </div>
        <!-- 사용여부 -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="합동점검항목 목록"
      rowKey="saiJointInspectionItemId"
      selection="multiple"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLADD" :showLoading="false" icon="add" @btnClicked="add" />
          <c-btn v-if="editable && grid.data.length > 0" label="LBLREMOVE" :showLoading="false" :editable="editable" icon="remove" @btnClicked="removeItem" />
          <c-btn
            v-if="editable && grid.data.length > 0"
            :url="insertUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveItem"
            @btnCallback="saveCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from "quasar";
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'consolidation-item',
  data() {
    return {
      searchParam: {
        plantCd: '',
        useFlag: 'Y',
        jointInspectionTypeCd: null,
        jointItemKindCd: null,
      },
      grid: {
        columns: [],
        data: [],
      },
      editable: true,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isSave: false,
      listUrl: '',
      insertUrl: '',
      searchUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.joint.items.url;
      this.insertUrl = transactionConfig.sai.joint.item.insert.url;
      this.deleteUrl = transactionConfig.sai.joint.item.delete.url;
      
      this.$comm.getComboItems('JOINT_INSPECTION_TYPE_CD').then(_result => {
        this.$comm.getComboItems('JOINT_ITEM_KIND_CD').then(_result2 => {
          this.grid.columns = [
            {
              name: 'plantCd',
              field: 'plantCd',
              label: 'LBLPLANT',
              align: 'center',
              sortable: false,
              required: true,
              style: 'width:150px',
              type: 'plant',
            },
            {
              required: true,
              setHeader: true,
              name: 'jointInspectionTypeCd',
              field: 'jointInspectionTypeCd',
              label: '합동점검종류',
              align: 'left',
              sortable: false,
              style: 'width:150px',
              comboItems: _result,
              type: 'select',
            },
            {
              required: true,
              setHeader: true,
              name: 'jointItemKindCd',
              field: 'jointItemKindCd',
              label: '점검/평가구분',
              align: 'left',
              sortable: false,
              style: 'width:150px',
              comboItems: _result2,
              type: 'select',
            },
            {
              required: true,
              setHeader: true,
              name: 'jointItemType',
              field: 'jointItemType',
              label: '구분',
              align: 'left',
              sortable: false,
              style: 'width:300px',
              type: 'text',
            },
            {
              required: true,
              name: 'jointItemName',
              field: 'jointItemName',
              label: '점검/평가항목',
              align: 'left',
              sortable: false,
              type: 'text',
            },
            {
              name: 'useFlag',
              field: 'useFlag',
              label: '사용여부',
              align: 'center',
              sortable: false,
              style: 'width:100px',
              type: 'check',
              true: 'Y',
              false: 'N',
            },
            {
              setHeader: true,
              name: 'sortOrder',
              field: 'sortOrder',
              label: '정렬순서',
              align: 'center',
              sortable: false,
              style: 'width:100px',
              type: 'number',
            },
          ]
          this.getList();
        });
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.push({
        plantCd: this.searchParam.plantCd,
        saiJointInspectionItemId: uid(),
        jointInspectionTypeCd: this.searchParam.jointInspectionTypeCd,
        jointItemKindCd: this.searchParam.jointItemKindCd,
        jointItemType: null,
        jointItemName: '',
        useFlag: 'Y',
        sortOrder: (this.grid.data.length + 1) * 10,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    saveItem() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid.data = this.$_.reject(this.grid.data, { saiJointInspectionItemId: item.saiJointInspectionItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
  }
};
</script>
